$primary: #0ab969;
$yellow: #ffb544;
$grey: #727884;
$blue: #4671f1;
$dark: #2c3439;

$borderRadius3: 3px;
$borderRadius5: 5px;

$xs: 0px;
$xs-max: 599px;
$sm: 600px;
$sm-max: 959px;
$md: 960px;
$md-max: 1279px;
$lg: 1280px;
$lg-max: 1919px;
$xl: 1920px;