@import './styles/variables.scss';

html, body, #root{
  height: 100vh;
}

body{
  background: #000;
  margin: 0;
  padding: 0;
  font-family: 'Open Sans', sans-serif, Arial;
  height: 100%;
  overflow: hidden;
}

body *{
  box-sizing: border-box;
}

h1, h2, h3, h4, h5, h6, p, a{
    color: white;
}

p, a{
    margin-top: 0;
}

.button-container{
    text-align: center;
}

.view-hidden{
    position: absolute;
    z-index: -9999;
}

.page{
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    &.notfound{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    
    &.uploader{
        
    }

    .dialog{
        width: 40%;
        padding: 20px;
        border-radius: $borderRadius5;
        background: $dark;
        box-shadow: 1px 1px 15px black;
        h4{
            color: white;
            font-size: 1.1rem;
            font-weight: bold;
            margin: -20px -20px 20px;
            padding: 12px 20px;
            border-bottom: 1px solid $grey;
        }
        .dialog-content{
            display: flex;
            flex-direction: column;
            justify-content: flex-start;
            align-items: center;
            width: 100%;
            font-size: 0.9rem;
            input[type="file"]{
                display: none;
            }
            .showKey{
                color: white;
                width: 100%;
            }
            // label{
            //     color: white;
            //     font-size: 0.8rem;
            // }
            button, label{
                border-radius: $borderRadius3;
                padding: 7px 15px;
                background: $primary;
                text-transform: uppercase;
                font-size: 0.75rem;
                font-weight: bold;
                text-shadow: 1px 1px 1px rgba(white, 0.2);
                box-shadow: 1px 1px 15px rgba(black, 0.2), -1px -1px 10px rgba(white, 0.2);
                outline: none;
                border: transparent;
                cursor: pointer;
                &:hover{
                    box-shadow: 1px 1px 15px rgba(black, 0.4), -1px -1px 10px rgba(white, 0.4);
                }
                &:active{
                    box-shadow: inset 1px 1px 15px rgba(black, 0.4), inset -1px -1px 10px rgba(white, 0.4);
                }
            }
        }
    }
}

.presentation-container{
  height: 100%;
  width: 100%;
  background: $dark;
}

.hidden{
    display: none;
}

.toolbar{
  position: absolute;
  z-index: 15;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  color: white;
  background: $dark;
  width: 100%;
  min-height: 50px;
  box-shadow: 0px 1px 15px rgba(black, 0.5);
  border-bottom: 1px solid $primary;
  ul{
    margin: 0;
    padding: 0;
    li{
      margin: 0;
      padding: 0;
      list-style: none;
      display: inline;
    }
  }
}

.presentation-tools{
  position: absolute;
  bottom: 20px;
  right: 20px;
  display: flex;
  align-items: stretch;
  border-radius: $borderRadius3;
  // border: 2px solid rgba(black, 0.4);
  background: rgba(black, 0.7);
  height: 50px;
  button{
    display: flex;
    cursor: pointer;
    background: none;
    border-radius: $borderRadius3;
    color: $primary;
    border: transparent;
    outline: none;
    &.left-arrow{
      margin-right: 2px;
      span{
        transform: rotate(180deg);
      }
      &:hover{
        text-shadow: -1px -1px 1px black;
      }
    }
    &:hover{
      text-shadow: 1px 1px 1px black;
    }
    &:active{
      background: rgba($dark, 0.5);
    }
    &[disabled]{
      cursor: not-allowed;
      background: rgba($dark, 0.2);
      color: $grey;
    }
  }
}

.input-button{
    display: flex;
    justify-content: space-between;
    align-items: center;
    input[type="text"]{
        background: lighten($dark, 20%);
        border: 1px solid rgba(black, 0.4);
        border-radius: 3px;
        outline: none;
        min-width: 200px;
        color: white;
        font-size: 0.85rem;
        padding: 6px 10px;
        margin-right: 20px;
        &:focus{
            box-shadow: 0 0 1px 1px rgba(black, 0.5);
        }
        &.errorStyle{
            border: 1px solid red;
            box-shadow: 0 0 1px 1px rgba(red, 0.2);
        }
    }
}

.presentation-area{
  background: #000;
  height: 100%;
  padding-top: 60px;
  .slide-container{
    height: 100%;
    .slide{
        width: 100%;
        height: 100%;
    }
  }
}

.slide-nav{
  height: 100%;
  box-sizing: border-box;
  // max-width: 200px;
  padding: 10px 8px;
  background: $dark;
  box-shadow: 1px 1px 10px $dark;
  overflow-y: auto;
  &.hide{

  }
  &.show{

  }
}

.slides-drawer{
  .MuiDrawer-paper{
    top: 65px;
    bottom: 0;
    height: auto;
  }
}

.slideListButton{
  padding-top: 3px !important;
  padding-bottom: 3px !important;
  &:hover:not(.selected){
    background-color: rgba(#eee, 0.1) !important;
  }
}

.slide-number-image{
  display: flex;
  align-items: center;
  padding: 5px;
  border-radius: 3px;
  margin: 5px 0;
  border: 2px solid transparent;
  box-sizing: border-box;
  &.active{
    border: 2px solid $primary;
    background: rgba(#eee, 0.15);
  }
  &:hover:not(.active){
    cursor: pointer;
  }
  .slide-number{
    color: #ccc;
    font-size: 0.8rem;
    font-weight: bold;
    text-shadow: 1px 1px 1px black;
    margin-right: 10px;
    min-width: 12px;
  }
  .slide-image{
    display: flex;
    margin: auto;
    border-radius: 3px;
    box-sizing: border-box;
    @media (max-width: $xs-max){
      height: 70px;
    }
    img{
      max-height: 100%;
      max-width: 100%;
      height: auto;
      width: auto;
      margin: auto;
    }
  }
}

#fileinput{
    position: relative;
    z-index: 9898989898989;
}


.progress{
    width: 100%;
    max-width: 500px;
    height: 20px;
    margin: 0 10px;
    overflow: hidden;
    background-color: #f5f5f5;
    border-radius: 3px;
    box-shadow: 0 0 1px 1px rgba(black, 0.8);
    .progress-bar{
        width: 100%;
        height: 20px;
        background-color: $primary;
        background-image: linear-gradient(-45deg, rgba(255, 255, 255, 0.15) 25%, transparent 25%, transparent 50%, rgba(255, 255, 255, 0.15) 50%, rgba(255, 255, 255, 0.15) 75%, transparent 75%, transparent);
        background-size: 40px 40px;
        animation: progress-bar-stripes 1s linear infinite;
    }
}

@keyframes progress-bar-stripes {
    from  { background-position: 0 0; }
    to    { background-position: 40px 0; }
}

.height100{
  height: 100%;
}

.mb30{
  margin-bottom: 30px;
}
.mb20{
  margin-bottom: 20px;
}